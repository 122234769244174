.news-detail 
	@apply bg-white
	padding: r(60px) 0
	@screen lg
		padding: r(100px)  0
	.side-social-wrap 
		@media screen and ( max-width: 1024px)
			@apply start-item pt-5
		a
			@apply center-item overflow-hidden transition border border-grey-200 rounded-full
			width: r(48px)
			height: r(48px)
			&:not(:last-child)
				@apply mr-3
				@screen lg
					@apply mb-3 mr-0
			&:hover
				@apply border-primary-600 bg-primary-600
				em
					@apply text-white
			em
				@apply  leading-none text-clamp-20 text-grey-400
				@screen xl
					@apply text-clamp-24

		@screen lg
			@apply absolute left-[-60px] top-0 z-30
	.wp-caption-text
		font-size: 14px !important
.briefcontent
	@apply text-clamp-20 font-bold leading-tight text-grey-950
	*
		@apply text-clamp-20 font-bold leading-tight text-grey-950
	@media screen and ( max-width: 1024px)
		@apply text-clamp-16
		*
			@apply text-clamp-16
.other-news
	.zone-title
		+fz(20px,24px)
		@apply text-left pb-4 border-b border-b-primary-600 text-primary-600 font-bold uppercase
.news-side
	@apply start-item border-b border-b-grey-200
	align-items: center
	padding: r(20px) 0 
	@media screen and ( max-width: 576px)
		@apply col-hor

	&:hover
		.headline
			a
				@apply underline
	time
		span
			@apply text-clamp-14

	.headline
		@apply my-auto
		a
			@apply text-clamp-14 font-medium
			+line(3)
	.img
		@media screen and ( max-width: 576px)
			@apply w-full mb-4
		a
			@apply overflow-hidden
			@media screen and ( max-width: 576px)
				+img-ratio(9/16)
				@apply w-full
			@screen sm
				@apply center-item mr-5
				height: 90px
				width: r(140px)
				min-width: r(140px)
				max-width: r(140px)
				img
					width: 100%
					height: 100%
					object-fit: cover
	
