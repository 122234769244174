.home-dealer
	margin-top: 10px
	@media screen and ( max-width: 1024px)
		@apply py-15
	.container-fluid
		@apply p-0
	.box
		@apply pt-10
		@screen lg
			padding: r(80px) r(40px) r(40px) 0
		&::after
			content: ''
			@apply block absolute pointer-events-none bottom-0 z-10  transition w-full h-full bg-white
			right: calc( 100% - 1px )
		@media screen and ( max-width: 1024px)
			@apply block text-center
		@screen xl
			width: 94%
	.img
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(2,3)
			@screen lg
				display: flex
				height: r(700px)
				width: auto
				img
					width: auto
					height: 100%
					object-fit: cover 
	.desc
		@apply text-clamp-20
		*
			@apply text-clamp-20
		span,strong
			@apply text-primary-600
	.wrap-left
		@media screen and ( max-width: 1024px)
			@apply justify-center