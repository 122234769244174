@layer components
	.block-title,.zone-title
		@apply text-clamp-36 font-semibold leading-tight mb-7
		*
			@apply text-clamp-36 font-semibold leading-tight
	.headline
		@apply text-clamp-20 font-semibold leading-tight
		*
			@apply text-clamp-20 font-semibold leading-tight
		&.t-12
			@apply text-clamp-12
			*
				@apply text-clamp-12
		&.t-18
			@apply text-clamp-18
			*
				@apply text-clamp-18
		&.t-24
			@apply text-clamp-24
			*
				@apply text-clamp-24
		&.white
			@apply text-white
			*
				@apply text-white
	.block-title,.zone-title,.headline,.sub-title
		@apply transition
		*
			@apply transition
	.title-line
		@apply bg-primary-50 start-item py-2 pl-5 text-primary-600 font-bold text-clamp-16 border-l-4 border-l-primary-600 leading-none
		*
			@apply text-primary-600 font-bold text-clamp-16 leading-none
	