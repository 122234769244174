.home-news
	.container
		@media screen and ( max-width: 576px)
			@apply pb-[60px]
	.title-wrap
		@media screen and ( max-width: 576px)
			@apply text-center center-item
			.btn-primary
				@apply absolute-x bottom-0
		
.news-big
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-20  transition w-full h-full
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%)
	.img
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(610,640)
			@screen lg
				@apply center-item
				height: r(610px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
	time
		@apply text-white block mb-4
		*
			@apply text-white
.news-item-side
	@apply start-item 
	align-items: flex-start
	&:hover
		.title
			a
				@apply underline
	time
		@apply mb-3 block
		@screen xl
			@apply mb-4
		span
			@apply text-clamp-14

	.title
		@apply my-auto
		a
			@apply  font-medium
			+line(3)
	.img
		flex: 0 0 auto
		a
			@apply overflow-hidden rounded center-item mr-5
			
			height: r(183px)
			width: r(250px)
			min-width: r(250px)
			max-width: r(250px)
			@media screen and ( max-width: 576px)
				width: r(200px)
				min-width: r(200px)
				max-width: r(200px)
			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		flex: 0 1 auto