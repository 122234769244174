
.gallery-list
	.container-grid
		display: grid
		@screen lg
			grid-template-columns: 1fr 1fr 1fr 1fr
			grid-template-rows: auto auto auto
			grid-auto-columns: 1fr
			gap: 32px 32px
			grid-auto-flow: row
			grid-template-areas: "item-1 item-1 item-2 item-3" "item-1 item-1 item-6 item-6" "item-4 item-5 item-6 item-6"
		@media screen and ( min-width: 1024.1px)  and ( max-width: 1280px)
			gap: 15px 15px
		@media screen and ( max-width: 1024px)
			@apply grid-cols-2 gap-5
	.item
		@media screen and ( max-width: 1024px)
			@apply col-span-1
		@media screen and ( max-width: 420px)
			@apply col-span-2
		@screen lg
			&:nth-child(1)
				grid-area: item-1
				.img
					a
						height: r(425px)
			&:nth-child(2)
				grid-area: item-2
			&:nth-child(3)
				grid-area: item-3
			&:nth-child(4)
				grid-area: item-4
			&:nth-child(5)
				grid-area: item-5
			&:nth-child(6)
				grid-area: item-6
				.img
					a
						height: r(425px)
		&:hover
			.txt
				@apply opacity-100
				span
					@apply underline
		.img
			@apply w-full block
			a
				@apply w-full block
				@media screen and ( max-width: 1024px)
					+img-ratio(425,640)
				@screen lg
					@apply center-item
					height: r(205px)
					width: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
		.txt
			@apply justify-center 
			background: linear-gradient(215.46deg, rgba(#FFA3BF, .9) -55.3%, rgba(#E31E26, .9) 100%)
.video-list
	.img
		a
			+img-ratio(278,416)
	.icon
		width: r(92px)
		height: r(92px)
		@apply bg-dark bg-opacity-70 transition pointer-events-none
		
		em
			@apply text-white text-clamp-36 leading-none
			@media screen and ( max-width: 1200px)
				@apply text-clamp-28
	.item
		&:hover
			.icon
				@apply bg-primary-600 bg-opacity-90
			.txt
				@apply border-b-primary-600
				a
					@apply text-primary-600