.about-1
	.row
		@apply m-0 p-0 justify-between
		.col
			@apply m-0 p-0
	.scollbar-wrap
		height: r(380px)
	h3,h4,h5
		@apply text-primary-600 font-bold text-clamp-20 mb-4
		@screen lg
			@apply mb-6
	.bg-wrap
		@apply px-8
		@media screen and ( max-width: 1200px)
			@apply py-15 px-4
		.headline
			@apply text-white mb-7 mt-3 text-center
			@screen xl
				@apply mb-10 mt-4
	.counter
		@apply text-white font-black leading-none
		font-family: 'Inter', sans-serif
		+fz(60px,72px)
		@screen lg
			+fz(72px,90px)
		@screen xl
			+fz(90px,120px)
		span
			@apply text-clamp-28 -ml-3
			font-family: 'Inter', sans-serif
			@screen sm
				@apply -ml-4 
			@screen xl
				@apply -ml-5 text-clamp-36
.about-2
	.row
		margin: 0 -8px 16px -8px
		.col
			padding: 0 8px
			@apply m-0
		&:nth-child(even)
			@apply flex-row-reverse
	.img
		a
			+img-ratio(433,650)
	.scollbar-wrap
		height: r(200px)
		@media screen and ( max-width: 768px)
			@apply h-auto
.about-3
	.four-swiper
		.swiper-slide
			@media screen and ( max-width: 460px)
				@apply w-1/2 px-2
.certificate-item
	.img
		a
			@apply center-item p-5 bg-white
			height: r(370px)
			width: auto
			@media screen and ( max-width: 460px)
				@apply p-4
			img
				width: auto
				height: 100%
				object-fit: contain
.about-4
	.five-swiper
		.swiper-slide
			@screen xl
				@apply  w-1/6
			@media screen and ( max-width: 460px)
				@apply w-1/2 px-2
	.img
		@apply transition
		&:hover
			@apply shadow-lg
		a
			@apply center-item p-5
			height: r(130px)
			min-height: 110px
			width: auto
			@media screen and ( max-width: 1200px)
				@apply p-4
			img
				width: auto
				height: 100%
				object-fit: contain