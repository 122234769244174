
.price-filter
	position: relative
	width: 100%
	.wrap-form
		@apply pt-5
		.form-group
			@apply mb-5
			input[type='text']
				border: 1px solid #E6E8F0
				height: 34px
		.frm-btnwrap
			@apply start-item w-full
			.frm-btn
				&::after
					@apply hidden
				input[type='submit']
					@apply bg-grey-50 border border-primary-600 rounded overflow-hidden font-semibold text-primary-600 uppercase w-[75px] h-[42px] transition  p-0
					&:hover
						@apply bg-primary-600 text-white
	.text-amount
		.form,.to
			@apply text-clamp-16 font-medium
			color: #050505
			*
				@apply text-clamp-16 font-medium
				color: #050505
		.to
			@apply text-primary-600
			*
				@apply text-primary-600

	.item
		@apply flex flex-col justify-center items-center 
	.price-slider
		width: 95%
		background: #DCDCDC
		margin: 15px 0 15px 10px
		position: relative
		transition: all,0s
		height: 3px
		border-radius: 4px
	.ui-widget-header 
		background: red
		position: absolute
		z-index: 1
		display: block
		top: 0
		transition: all,0s
		border-radius: 4px
		@apply overflow-hidden
	.ui-slider-horizontal
		.ui-slider-handle 
			touch-action: none
			border-radius: 4px
			@apply bg-white border border-primary-600 z-20 absolute-y rounded-full overflow-hidden
			width: 12px  !important
			height: 12px  !important
	.min-input,.max-input 
		@apply text-primary-600 text-14 font-normal overflow-hidden
		border-radius: 4px