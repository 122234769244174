main
.qradio
	input[type='radio']
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		width: 100%
		height: 100%
		opacity: 0
		z-index: 20
		&:checked ~ label
			&::before
				background: url('../img/icon/circle-dot.svg')
	label
		@apply  relative z-10 pointer-events-none transition 
		&::before
			content: ''
			background: url('../img/icon/circle.svg')
			background-repeat: no-repeat !important
			background-size: contain !important
			background-position: center !important
			display: block
			position: absolute
			pointer-events: none
			left: -30px
			top: 50%
			transform: translateY(-50%)
			z-index: 9
			height: 18px
			width: 18px
			transition: .3s all ease-in-out
.commision-policy
	@apply py-10
	.news-detail
		@apply pt-0 