.home-tour-category
	@apply overflow-hidden
	&::before,&::after
		content: ''
		@apply block absolute pointer-events-none z-10 opacity-50
		background-repeat: no-repeat !important
		background-size: contain !important
	&::before
		@apply left-0 bottom-0 
		background: url('../img/overlay/fish-left.svg')
		width: r(585px)
		height: r(420px)
		background-position: left center
		@media screen and ( max-width: 1024px)
			width: r(485px)
			height: r(320px)
	&::after
		@apply right-0 top-[60px]
		background: url('../img/overlay/fish-right.svg')
		width: r(330px)
		height: r(467px)
		background-position: right center
		@media screen and ( max-width: 1024px)
			@apply top-0
			width: r(230px)
			height: r(367px)
	.container
		@media screen and ( max-width: 576px)
			@apply pb-[60px]
	.title-wrap
		@media screen and ( max-width: 576px)
			@apply text-center center-item
			.btn-primary
				@apply absolute-x bottom-0
	.tab-nav
		@media screen and ( max-width: 400px)
			+res-nav