.global-breadcrumb
	@apply  flex justify-start items-center py-2 bg-grey-50
	min-height: 50px
	ol
		@apply p-0 list-none
		li
			@apply inline-flex relative leading-none
			padding:  0 14px
			&:after
				content: '\f054'
				font-family: 'Font Awesome 6 Pro' 
				@apply text-grey-700 block font-normal absolute-y pointer-events-none z-10 left-full flex items-center
				font-size: 14px
				height: 20px
				width: 12px
				@media screen and ( max-width: 1024px)
					font-size: 14px
			&:first-child
				@apply pl-0
				span,a
					font-size: 0 !important
				&:before
					content: '\f015'
					font-family: 'Font Awesome 6 Pro'
					@apply text-grey-700 block pointer-events-none z-10 font-normal flex items-center
					font-size: 16px
					height: 20px
					width: 16px
			&:last-child
				&:after
					@apply hidden
				a
					@apply text-grey-700
			a,span
				@apply text-grey-700 font-normal text-clamp-14