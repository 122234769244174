
.sort-filter
	@apply end-item
	label
		@apply font-bold uppercase whitespace-nowrap mr-4
		@screen xl
			@apply mr-5
	.custom-select
		select
			@apply text-grey-700 bg-grey-50
.filter-wrap 
	@media screen and ( max-width: 1024px)
		@apply fixed top-[60px] left-0  w-full z-50 bg-white p-8 shadow-lg border-t border-t-grey-200 opacity-0 transition pointer-events-none
		&.is-open
			@apply opacity-100 pointer-events-auto
	.card-body
		padding: r(20px) r(20px) r(35px) r(20px)
	.filter-item
		@apply mb-7
		.frm-btn
			a
				@apply center-item text-primary-600 border border-primary-600 bg-transparent py-1 px-6 uppercase font-semibold rounded text-clamp-14 transition
				height: 42px
				&:hover
					@apply bg-primary-600 text-white
	.close-filter 
		@apply hidden
		@media screen and ( max-width: 1024px)
			@apply end-item mb-5
		span
			@apply text-clamp-16 mr-4 font-bold
		em
			@apply text-clamp-40 text-primary-600
.product-list
	.title-wrap
		@media screen and ( max-width: 576px)
			@apply col-ver
.filter-mobile-toggle
	@apply fixed right-[5px] z-30 top-50 translate-y-50 col-ver bg-primary-600 p-2
	@screen lg
		@apply hidden 
	em,span
		@apply text-white text-clamp-12 leading-none
	em
		@apply text-clamp-22 mb-1
.side-nav-wrap
	@media screen and ( max-width: 1024px)
		@apply mb-0
	.side-nav-toggle
		@apply gap-wrap
		em
			@apply hidden
			@media screen and ( max-width: 1024px)
				@apply inline-block text-white text-clamp-24
	.side-nav-menu
		@media screen and ( max-width: 1024px)
			@apply hidden
		li
			&:first-child
				a
					@apply pt-0
			&.active,&:hover
				a
					@apply text-primary-600 border-b-primary-600
		a
			@apply text-clamp-16 font-medium start-item py-3 border-b border-b-grey-200 transition