.wrap-form
	@apply relative
	.row
		box-shadow: none !important
	.fa-exclamation-triangle
		@apply absolute font-light text-red
		font-size: 12px
		bottom: -20px
		left: 15px
		font-family: 'Montserrat', sans-serif
		&::before
			font-family: 'Font Awesome 6 Pro'
		@media screen and ( max-width: 576px)
			font-size: 10px
			@apply font-bold
	.col
		@apply mb-4 relative
		&:last-child
			@apply mb-6
	.form-group
		>span
			@apply w-full
		input[type='text'],textarea,select,input[type='email'],input[type='tel'],input[type='password']
			height: 50px
			@apply  bg-transparent border border-grey-100 px-5 bg-white w-full text-clamp-16
			@media screen and ( max-width: 1200px)
				height: 40px
			&:focus
				@apply border-grey-999
			&::placeholder
				@apply  text-grey-666 text-clamp-14
		textarea
			height: 80px
			@apply pt-3
		label
			&.required
				&::after
					content: "("'*'")"
					display: inline-block
					font-size: r(16px)
					@apply text-red
	.frm-btnwrap
		@apply center-item  mt-0 w-fit mx-auto cursor-pointer
		@media screen and ( max-width: 1200px)
			margin-bottom: 0 !important
		label
			display: none !important
		.frm-btn
			@apply relative  mt-0 
			&:hover
				&::after
					@apply text-primary-600
				input[type='submit']
					@apply  bg-white text-primary-600
			&::after
				font-family: 'Font Awesome 6 Pro'
				content: '\f138'
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[25px]  z-10  transition font-black text-white  text-clamp-20
				height: 18px
				width: 20px
				line-height: 18px
		
				@media screen and ( max-width: 1024px)
					right: 20px
			input[type='submit']
				width: 120px
				height: 44px
				@apply transition text-white font-semibold text-clamp-14 center-item px-6 pr-12 overflow-hidden rounded border border-primary-600 bg-primary-600 cursor-pointer
				@media screen and ( max-width: 1024px) 
					height: 40px
					@apply px-3  pr-8
.wrap-form-2
	.form-group
		margin-bottom: 0 !important
		@apply text-white text-clamp-14 font-medium mb-3 inline-block relative
		label
			@apply text-white text-clamp-14 font-medium mb-3 inline-block
			&.required
				&::after
					content: "("'*'")"
					display: inline-block
					font-size: r(16px)
					@apply text-red
		input[type='text'],textarea,select,input[type='email'],input[type='tel'],input[type='password']
			@apply bg-grey-800 text-white text-clamp-16 px-4 w-full
			height: r(48px)
			min-height: 40px
			@screen lg
				@apply px-5
			&::placeholder
				@apply text-grey-300 text-clamp-14
		textarea
			height: 80px
			@apply pt-3
	.frm-btnwrap 
		@apply w-full center-item 
		.frm-btn
			@apply relative  mt-0  
			&::before
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-30  transition w-full h-full  opacity-0
				background: white !important
			&::after
				font-family: 'Font Awesome 6 Pro'
				content: '\f178'
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[25px]  z-10  transition font-normal  text-grey-15 text-clamp-20 h-[18px] w-[20px] leading-none
			input[type='submit']
				@apply text-grey-15  font-medium  w-full text-clamp-16  bg-gradient pointer-events-auto cursor-pointer relative z-30
				height: r(48px)
				min-height: 40px
				min-width: 150px
			&:hover
				&::before
					@apply opacity-100	
				input[type='submit']
					@apply text-grey-15 
					-webkit-text-fill-color: unset 
					-webkit-background-clip: unset
					border-image-source: linear-gradient(270deg, #fff 1.1%, #fff 52.1%, #fff 100%) !important
					background: none !important
.input-group,.form-password
	@apply relative
	.reveal-password
		@apply absolute top-50 right-[20px] -translate-y-1/2
		em
			@apply font-light
			font-size: 14px
.pwd-input-group
	input
		@apply w-full
	&.show
		.reveal-password
			em
				&::before
					content: '\f06e' !important
.frm-btn-reset
	display: none
.frm-captcha
	margin: 40px 0 0  0 !important
	display: inline-flex
	flex-direction: row-reverse
	img
		height: 44px !important
		max-width: auto
		max-width: 150px
		@media screen and ( max-width:1200px)
			max-width: 150px
			
	@media screen and (max-width:576px)
		flex-direction: column-reverse
	.frm-captcha-input
		@apply mt-0
		margin-left: 50px
		@media screen and ( max-width:576px)
			margin-left: 0
			@apply mt-4
		label
			display: none
		input
			padding: 0 10px
			margin-top: 0
			height: 30px
			@media screen and ( max-width:1200px)
				max-width: 200px
			@media screen and ( max-width:1200px)
				max-width: 150px
				
			@media screen and ( max-width:1024px)
				max-width: 200px
				
			@media (max-width: 767.98px)
				margin-left: 20px
			@media (max-width: 576.98px)
				margin-left: 0
	.rcRefreshImage
		position: absolute
		opacity: 1
		cursor: pointer
		height: 0
		width: 0
		right: -5px
		top: 5px
		color: #ffffff
		z-index: 0
		font-size: 0
		&:before
			font-family: 'Font Awesome 6 Pro'
			position: absolute
			top: 0
			right: -40px
			opacity: 1
			z-index: 99
			color: #9d9080
			font-size: 30px
			content: "\f01e"
			display: flex
			justify-content: center
			align-items: flex-start
			@media screen and ( max-width:576px)
				height: 50px
@media screen and ( max-width:500px )
	#ctl00_mainContent_ctl05_captcha_ctl00
		display: none
#ctl00_mainContent_ctl02_captcha_ctl01
	position: relative
#ctl00_mainContent_ctl06_btnReset,#ctl00_mainContent_ctl05_captcha_ctl00,#ctl00_mainContent_ctl08_captcha_ctl00,#ctl00_mainContent_ctl07_captcha_ctl00
	display: none
#ctl00_mainContent_ctl06_txtCaptcha
	margin-left: 10px
#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton
	#text
		display: none
.RadCaptcha
	@apply relative
	@media screen and ( max-width:767.5px)
		margin-left: 0
	span
		@apply hidden
	#ctl00_mainContent_ctl04_captcha_ctl00
		display: block !important
		position: absolute
		left: 0
		top: 100%
		@apply text-red text-12
	>div
		>div
			display: flex
			position: relative
			flex-direction: column-reverse
			height: 40px !important
			margin-top: 0
			@media (max-width: 767.98px)
				margin-right: 0
				width: 180px