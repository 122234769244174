.homeland-tour
	@apply overflow-hidden
	&::before,&::after
		content: ''
		@apply block absolute pointer-events-none z-10 opacity-50
		background-repeat: no-repeat !important
		background-size: contain !important
	&::before
		@apply left-0 bottom-0 
		background: url('../img/overlay/fish-left.svg')
		width: r(585px)
		height: r(420px)
		background-position: left center
		@media screen and ( max-width: 1024px)
			width: r(485px)
			height: r(320px)
	&::after
		@apply right-0 top-[60px]
		background: url('../img/overlay/fish-right.svg')
		width: r(330px)
		height: r(467px)
		background-position: right center
		@media screen and ( max-width: 1024px)
			@apply top-0
			width: r(230px)
			height: r(367px)
.worldwide-tour
	background: url('../img/bg/home-bg-1.jpg')
	background-repeat: no-repeat
	background-size: 100% auto
	background-position: center
.product-category
	.title-wrap
		@media screen and ( max-width: 576px)
			@apply col-ver
.product-item
	box-shadow: 0px 0px 20px 0px #00000012
	@apply bg-white rounded overflow-hidden relative h-full
	.img
		a
			+img-ratio(213,304)
	.location
		@apply text-grey-400 leading-none text-clamp-14
		*
			@apply text-grey-400 leading-none text-clamp-14
		em
			@apply font-light text-clamp-18 mr-2 
	.txt
		padding: r(24px) r(20px)
	.price
		@apply text-primary-600 font-semibold text-clamp-18 leading-none
		.current
			@apply text-primary-600 font-semibold text-clamp-18 leading-none block
		.old
			@apply text-grey-400 font-normal text-clamp-14 leading-none mt-2 block line-through
	.options
		@apply absolute top-[20px] left-[10px] z-20 pointer-events-none text-clamp-14 text-white font-semibold start-item
		align-items: center
		*
			@apply text-clamp-14 text-white font-semibold leading-none
		em
			@apply text-clamp-12 ml-2
			*
				@apply text-clamp-12
		.discount,.types
			@apply overflow-hidden rounded center-item 
			padding: 2px r(10px)
			height: 22px
			min-height: 22px
		.discount
			background: #FFB617
			@apply mr-[10px]
		.types
			background: #E41913
			&:not(:last-child)
				@apply mr-1
	.headline
		@apply py-2
		a
			+clamp(16px,20px,1.2,2)
	.price-wrap
		@apply flex justify-between items-center mb-4 mt-2
		@screen lg 
			@apply mb-5
	.tour-info-item
		@apply gap-wrap
		.label 
			@apply text-clamp-14 whitespace-nowrap mb-0
	.custom-select 
		width: 125px
		@apply ml-auto shadow-lg rounded-4 overflow-hidden
		select
			min-width: 125px
			@apply pr-8 pl-4 py-1 h-[36px]
		&::after
			@apply right-[12px] text-clamp-12 h-[13px]
	&:hover
		.headline
			a
				@apply underline