@layer components
	.desc,.zone-desc
		@apply text-clamp-16 leading-normal
		*
			@apply text-clamp-16 
		strong
			@apply font-bold
			*
				@apply font-bold  

.fullcontent
	@apply font-medium leading-normal text-clamp-16 
	*
		@apply font-medium leading-normal text-clamp-16 
	img,iframe
		@apply block mx-auto my-4 
	p
		@apply mb-3 text-clamp-16
		*
			@apply text-clamp-16
	ul,ol
		list-style-type: disc
		padding-left: 0
		li
			@apply mb-3 flex items-start
			&::before
				content: ''
				@apply block mt-2 pointer-events-none z-10  transition w-[6px] h-[6px] bg-primary-600 rounded-full mr-3 min-w-[6px]
	hr
		@apply block my-4
	h2,h3,h4,h5,h6
		@apply  mb-4 font-bold  text-primary-600
	strong
		font-weight: 700 !important
		*
			font-weight: 700 !important
	h2
		@apply text-clamp-32
	h3
		@apply text-clamp-28
	h4
		@apply text-clamp-24
	h5
		@apply text-clamp-20
	h6
		@apply text-clamp-16
	.row
		@apply mb-0
	table
		@apply w-full mb-5
		thead
			tr
				th
					@apply  text-white text-16 font-bold text-left  py-4  px-6 bg-grey-666
					@media screen and ( min-width:1024px)
						&:first-child
							@apply pl-6
		tbody
			tr
				&:nth-child(even)
					background: #f5f5f5
				td
					@apply py-4 px-6 border border-grey-200
					&:first-child
					@media screen and ( min-width:1024px)
						@apply pl-5
					&:last-child
						@apply  text-right pr-5
	a
		@apply underline cursor-pointer
		color: #0058ff 