header
	height: 100px
	@apply fixed top-0 left-0 right-0  bg-white w-full transition 
	z-index: 9999
	padding: 0 15px
	@screen xl
		padding: 0 r(75px)
	@media screen and ( max-width: 1200px)
		height: 60px
	.container-fluid
		@apply end-item p-0
body
	&.overlay-bg
		@apply overflow-hidden
	&.minimize
		@media screen and ( min-width: 1200px)
			header
				height: 70px
			.search-overlay
				top: 70px
			.header-nav-brand
				a
					height: 50px
.header-nav-brand
	@apply z-20 absolute-y left-0
	@media screen and ( max-width: 576px)
		@apply absolute-center
	a
		height: 50px
		@apply transition start-item  w-auto
		img,svg
			width: 100%
			height: 100%
			object-fit: contain
.header-dealer-wrap
	@media screen and ( max-width: 1200px)
		@apply hidden
		.Module,.ModuleContent
			@apply w-full
.nav-primary-menu
	@screen xl
		@apply h-full
		padding-right: r(40px)
	@screen 2xl
		padding-right: r(80px)
	@media screen and ( max-width: 1200px)
		@apply hidden  w-full py-5
	.nav
		@apply gap-item
		@media screen and ( min-width: 1200px)
			@apply h-full
		@media screen and ( max-width: 1200px)
			@apply col-hor w-full h-auto
	.drop-down
		@media screen and ( max-width: 1200px)
			@apply flex flex-col
		&.is-open
			.title
				em
					&:before
						transform: rotateX(180deg)
		.nav-link-sub
			@apply start-item 
			&.active,&.current-project-ancestor
				a
					@apply underline
			a
				@apply normal-case font-medium py-3 px-0 text-grey-15 
				justify-content: flex-start
				font-size: clamp(16px,r(18px),18px)
				@media screen and ( max-width: 1200px)
					@apply pt-4 pl-6 text-grey-900
				&:hover
					@apply underline
		.title
			@apply relative
			@media screen and ( max-width: 1200px)
				@apply gap-item w-full 
			@media screen and ( min-width: 1200px)
				@apply center-item p-0 h-full
			a
				@apply  relative  transition px-2 py-0
				&::after
					content: ''
					height: 1px
					@apply block absolute-x pointer-events-none bottom-0 z-10  transition  w-0 bg-primary-600
				@media screen and ( max-width: 1200px)
					@apply py-5 px-0
					&::after
						@apply hidden
			em
				&:before
					@apply transition
				@screen xl
					@apply hidden 
				@media screen and ( max-width: 1200px)
					width: 40px
					height: 30px
					font-size: 20px
					@apply  absolute right-0 top-50 translate-y-50 text-grey-300 end-item
		@media screen and ( min-width: 1200px)
			&:hover
				.nav-sub
					@apply opacity-100 pointer-events-auto transform-none
		.nav-sub
			@media screen and ( max-width: 1200px)
				@apply hidden  py-2 w-full
			@media screen and ( min-width: 1200px)
				@apply absolute top-full left-0 w-max z-20 opacity-0 pointer-events-none border-none py-2 px-6 transition bg-white
				transform: translateY(25%)
	.nav-link
		@apply relative z-10 
		@media screen and ( max-width: 1200px)
			@apply  w-full start-item 
		@media screen and ( min-width: 1200px)
			margin: 0 r(8px)
			@apply h-full  center-item
			&:first-child
				margin-left: 0
			&:last-child
				margin-right: 0
		@media screen and ( min-width: 1600px)
			margin: 0 r(22px)
			
				
		&.active,&:hover,&.current-project-ancestor
			.title
				a
					@apply text-primary-600
					&::after
						@apply w-full
			>a
				@apply text-primary-600
				&::after
					@apply w-full
		>a
			&::after
				content: ''
				@apply block absolute-x pointer-events-none bottom-0 z-10  transition  w-0 bg-primary-600
				height: 2px
				@media screen and ( max-width: 1200px)
					@apply hidden
			@media screen and ( max-width: 1200px)
				@apply py-4
			@media screen and ( min-width: 1200px)
				padding: r(8px) 0
				@apply h-full
		a
			@apply center-item leading-none font-medium relative uppercase text-grey-950 transition text-clamp-20
			@media screen and ( max-width: 1200px)
				@apply text-grey-950 font-semibold normal-case
			@media screen and ( min-width: 1200px)
				@apply text-clamp-15 h-full 
			@media screen and ( min-width: 1280px)
				@apply text-clamp-16
.site-menu-toggle
	@apply  center-item items-center h-full mt-1
	@screen xl
		@apply hidden
.mobile-nav-wrap
	@apply fixed top-0 left-0 w-full h-full  h-screen px-6 py-8 bg-grey-50
	z-index: 9999
	transform: translateX(-100%)
	transition: transform .8s cubic-bezier(.165,.84,.44,1)
	@screen md
		width: 320px
	@media screen and ( min-width:1200px )
		@apply hidden 
	&.is-open
		transform: none
	.close-menu
		@apply start-item 
		em,span
			@apply  text-grey-950  leading-none
		em
			@apply text-clamp-32
		span
			@apply text-clamp-20 ml-3 font-semibold
	.mobile-top-nav
		@apply gap-wrap mb-5
