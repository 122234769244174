.language-wrap
	height: 32px
	@apply m-0
	@media screen and ( max-width: 576px)
		
		@apply absolute-y left-0
		position: absolute !important
	@screen xl
		height: 36px
		@apply mr-0
	.Module
		@apply h-full
	a
		&:hover
			@apply underline
	.current-language
		padding: 0 !important
		@screen sm
			padding: 0 r(40px) !important
		@screen xl
			padding: 0 r(24px) !important
		@screen 2xl
			padding: 0 r(36px) !important
		em,span
			@apply text-grey-950 font-light
		span
			@apply text-clamp-20 font-normal
			@screen xl
				@apply text-clamp-14
		.fa-earth
			@apply text-clamp-24
			@screen xl
				@apply text-clamp-18
		.fa-chevron-down
			@apply text-clamp-18
			@screen xl
				@apply text-clamp-12
	.dropdown
		@apply shadow-md