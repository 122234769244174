.toggle-item
	@apply overflow-hidden mb-4  overflow-hidden
	&.is-toggle
		.title
			@apply bg-primary-600
			.number
				@apply text-white
			span
				-webkit-line-clamp: unset
				@apply text-white
				&:before 
					@apply text-white
			em
				@apply text-white
				transform: scaleY(-1)
					
		.article
			@apply block
	.title
		@apply gap-item transition py-2 px-5 bg-grey-50
		min-height: 60px
		@screen lg
			@apply py-3 
			min-height: 60px
		p
			@apply w-full m-0 flex items-center leading-none
			.number
				@apply text-primary-600 font-bold text-clamp-24 mr-3
				@screen lg
					@apply mr-4
				*
					@apply text-primary-600 font-bold text-clamp-24
					
			span
				@apply text-clamp-16 font-medium leading-none text-grey-950
		em
			@apply  ml-5 inline-block text-grey-500
			font-size: 18px
			height: 15px
			width: 16px
			transition: .3s all  ease-in-out
			@media screen and ( max-width: 1023.98px)
				font-size: 16px
				height: 16px
				width: 16px
	.article
		@apply hidden text-clamp-16 font-normal bg-grey-50 p-5 leading-normal
		*
			@apply  text-clamp-16 font-normal leading-normal
		p
			@apply block mb-4
		ul
			@apply mt-4
			padding-left: 20px
			list-style-type: disc
			li
				@apply mb-4   
		strong,h3,h4
			@apply font-bold
		img
			@apply block mx-auto my-4
		ul
			list-style-type: disc
			li
				@apply mb-2
