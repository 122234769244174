//Type 1 first child icon
.modulepager,.post-nav
	@apply flex items-center justify-center pt-6 pb-0
	@media screen and ( max-width:1024px)
		padding-bottom: 30px
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&.active,&:hover
				a,span
					@apply bg-primary-600 text-white
			
			
			span,a
				width: 44px
				height: 44px
				text-decoration: none
				@apply text-grey-700  text-clamp-16 center-item transition border border-grey-200 font-medium
				@media screen and ( max-width:1024px)
					height: 32px
					width: 32px
		.next
			a
				width: max-content
				@apply whitespace-nowrap
					
