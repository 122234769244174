.home-comment
	padding: r(60px) 0
	@apply overflow-hidden
	@screen xl
		padding: r(80px) 0 r(100px)
	.block-wrap
		.container
			@screen lg
				@apply absolute-center w-full h-full pointer-events-none
			.row
				@apply h-full
	.swiper-pagination
		bottom: -40px !important
		.swiper-pagination-bullet 
			@apply opacity-100 bg-transparent relative
			width: 16px !important
			height: 16px !important
			@media screen and ( max-width: 1024px)
				width: 24px !important
				height: 24px !important
			&::after
				content: ''
				@apply block absolute-center rounded-full pointer-events-none z-10 bg-white bg-opacity-50 transition w-[6px] h-[6px]
				@media screen and ( max-width: 1024px)
					@apply w-[14px] h-[14px]
			&.swiper-pagination-bullet-active
				background: url('../img/icon/radio.svg')
				background-repeat: no-repeat
				background-size: 100% 100%
				background-position: center
				&::after
					@apply hidden
	.swiper-cmt-bg
		@apply h-full
		.swiper,.swiper-wrapper,.swiper-slide 
			@apply h-full
		.bg
			@apply h-full
			a
				@apply center-item
				height: 100%
				width: 100%
				&::after
					content: ''
					@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
					background: linear-gradient(0deg, #00000038 30%, #ffffff44 100%)
				img
					width: 100%
					height: 100%
					object-fit: cover
	
	.swiper-cmt-txt
		@apply pointer-events-auto
		
	
	.swiper-cmt-image
		@screen md
			max-width: 680px
		@media screen and ( min-width: 768px) and ( max-width: 1024px)
			@apply mx-auto mb-10
		.swiper-slide
			@apply opacity-0 transition
			padding: 60px 75px
			@media screen and ( max-width: 576px)
				padding: 25px 
			&.swiper-slide-active
				@apply opacity-100
	.img-wrap
		@apply flex
		&:hover
			.img
				&:nth-child(1)
					transform: rotate(-20deg)
				&:nth-child(2)
					transform: rotate(9deg)
	.img
		@apply w-1/2 relative z-10 transition
		a
			@apply center-item p-[5px] bg-white rounded overflow-hidden
			height: r(350px)
			max-height: 320px !important
			width: 100%
			@media screen and ( max-width: 768px)
				height: r(300px)
			@media screen and ( max-width: 576px)
				height: r(250px)
			img
				width: 100%
				height: 100%
				object-fit: cover
		&:nth-child(1)
			transform: rotate(-27.19deg)
			@apply mt-15
			@media screen and ( max-width: 768px)
				transform: rotate(-15deg)
		&:nth-child(2)
			@apply z-30 
			transform: rotate(12deg)
			@media screen and ( max-width: 768px)
				transform: rotate(8deg)
	