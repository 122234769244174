footer
	.logo
		a
			@apply start-item
			height: r(60px)
			width: auto
			@media screen and ( max-width: 1024px)
				@apply justify-center mb-4
			img
				width: auto
				height: 100%
				object-fit: contain
	.phone
		@apply mb-4
		p
			@apply text-clamp-14
			strong
				@apply font-semibold text-clamp-24
	address
		li
			@apply text-clamp-16 mb-2
			*
				@apply text-clamp-16
		a
			@apply relative
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-[1px] bg-white
	ul
		li
			@apply mb-3
			&.active,&:hover
				a
					@apply underline
		a
			@apply text-clamp-16 font-normal 
	.subscribe
		.fa-exclamation-triangle
			@apply absolute bottom-[-20px] left-0
			font-family: 'Montserrat', sans-serif
			&::before
				font-family: 'Font Awesome 6 Pro'
		.subscribefrm
			@apply relative
			&::after
				font-family: 'Font Awesome 6 Pro'
				content: '\e20a'
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[25px]  z-10  transition font-black text-primary-600 text-clamp-20 pointer-events-none
				height: 18px
				width: 20px
				line-height: 18px
			input
				height: 50px
				@apply rounded overflow-hidden w-full text-clamp-14 text-primary-600
				padding: 4px 50px 4px 20px
				&::placeholder
					@apply  text-clamp-14 text-primary-600 !#{important}
			button
				@apply center-item absolute-y right-0 px-5 h-full relative
				font-size: 0
				width: 60px !important
				em
					@apply hidden
			.fa-exclamation-triangle
				color: white !important
				*
					color: white !important
				&:before
					color: white !important
				
	.copyright
		@apply text-clamp-14  font-normal
		*
			@apply text-clamp-14  font-normal
		@media screen and ( max-width: 576px)
			@apply order-2 pt-6 text-center leading-normal
	.social-network
		.ModuleContent
			@apply end-item
		@media screen and ( max-width: 576px)
			@apply order-1
		a
			@apply center-item cursor-pointer
			&:not(:last-child)
				@apply mr-5
				@screen xl
					@apply mr-6
			&:hover
				em
					transform: translateY(-3px)
		em
			@apply leading-none text-clamp-24 transition
	.footer-bottom
		@media screen and ( max-width: 576px)
			@apply col-ver