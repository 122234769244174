.contact-us
	.row
		@apply justify-between m-0 p-0
		.col
			@apply m-0 p-0
	address
		&:last-child
			@apply border-b border-b-grey-200
		li
			@apply start-item
			align-items: center
			&:not(:last-child)
				@apply  mb-2
		em
			@apply text-clamp-20 text-primary-600 mr-4 mt-1
			min-width: 20px
			@screen xl
				@apply mr-6
		p,span,a
			@apply text-clamp-16 font-medium
		span
			@apply text-grey-950
		a
			@apply underline cursor-pointer
			color: #008BFF
	.wrap-form
		.form-group
			@apply relative
			textarea
				height: 120px
			.fa-exclamation-triangle
				left: 0 !important
		.frm-btn
			@apply mt-5