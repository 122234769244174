.primary-banner
	@apply relative overflow-hidden
	.banner-container
		@apply relative
		@media screen and ( max-width: 1200px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 550/750 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.wrap
		@apply relative
	.img
		@apply relative
		@media screen and ( min-width: 1200.1px)
			a
				+img-ratio(680,1920)
		@media screen and ( max-width: 1200px)
			a
				+img-ratio(550,750)
	.swiper-pagination
		width: 100% !important
		bottom: r(30px) !important
		@apply center-item mx-auto  
		@media screen and ( max-width: 576px)
			bottom: r(20px) !important
	.swiper-slide
		@media screen and ( max-width: 1024px)
			@apply opacity-0 transition
		&.swiper-slide-active
			@apply opacity-100
			.title
				@apply opacity-0
				animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) .7s both
	.swiper-pagination-bullet
		@apply  rounded-full transition opacity-100 center-item bg-transparent relative  center-item overflow-hidden
		border: 2px solid rgba(#fff,.5 )
		height: 12px
		width: 12px
		&.swiper-pagination-bullet-active
			@apply opacity-100 border-white bg-white
			&::after
				content: ''
				@apply bg-white