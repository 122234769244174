.support-page
	.zone-nav
		@media screen and ( max-width: 768px)
			+res-nav
.policy-list
	.fullcontent
		hr
			@apply text-grey-200  block mb-4
		h4
			@apply text-clamp-24 mb-4
		h6
			@apply text-clamp-16 mb-4