.account-wrap
	@apply relative mr-6
	height: 32px
	@screen sm
		@apply mr-8 
	@screen xl
		@apply mr-0
		height: 36px
	.before-login
		display: none !important
	.user 
		padding: 0 !important
		span,.before-login
			@media screen  and ( max-width: 1440px)
				@apply hidden
		.fa-chevron-down 
			@media screen  and ( max-width: 1440px)
				@apply ml-3
	em
		@apply text-primary-600 text-clamp-24 px-1
		@screen xl
			@apply text-clamp-16 p-0
	span,.fa-chevron-down
		@apply hidden
	.fa-user
		@apply block
	.dropdown
		// @media screen and ( max-width: 1200px)
		// 	left: unset !important
		// 	right: 0 
		// 	transform: translateX(0) !important
.alert 
	position: relative 
	padding: 0.75rem 1.25rem 
	margin-bottom: 1rem 
	border: 1px solid transparent 
	border-radius: 0.25rem 
	display: block 
	line-height: 1.25 
	button 
		display: none 
	&.alert-danger 
		color: #721c24 
		background-color: #f8d7da 
		border-color: #f5c6cb 
	&.alert-success 
		color: #155724 
		background-color: #d4edda 
		border-color: #c3e6cb 
.changepasswordpage 
	table
		@apply w-full
		td
			@apply text-left text-grey-333 font-bold text-clamp-16
		input[type='submit']
			height: 40px
			@apply center-item px-6 py-2 bg-primary-600 text-white  font-bold  rounded-1 overflow-hidden mx-auto mt-5 transition text-clamp-14 border border-primary-600 border-solid
			width: 200px
			&:hover
				@apply bg-white text-primary-600
.registerpage 
	.wrap-register
		max-width: 600px !important
		.form-group
			margin-bottom: 10px
		input[type="text"],input[type="password"]
			border: 1px solid #f0eeee !important
			@apply rounded overflow-hidden
		label
			@apply text-clamp-14 font-semibold mb-1
.login-box,
.wrap-register,
.wrap-recover,
.wrap-resetpass,
.wrap-confirmreg 
	max-width: 400px 
	margin: 60px auto 
	padding: 1.875rem 
	background: #fff 
	border-radius: 10px 
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
@media screen and ( max-width: 1024px)
	.login-box,
	.wrap-register,
	.wrap-recover,
	.wrap-resetpass,
	.wrap-confirmreg 
		font-size: 14px 
	.order-product table 
		width: 150% 
	.wrap-confirmreg 
		background: transparent 
		max-width: 600px 
		-webkit-box-shadow: none 
		box-shadow: none 
.user-page
	@media screen and ( max-width: 1024px)
		@apply pt-5
	.order-info
		@apply mb-8
	.order-information-wrapper
		.col-12
			@apply mb-8
	.order-product
		@apply mt-7
	.col-xs-12
		margin-bottom: 30px
	.form-horizontal
		.form-group
			@apply mb-4
			@media screen and ( max-width: 576px)
				@apply w-full
				margin-top: 20px !important
		label
			@apply text-grey-950 font-medium text-clamp-14 mb-2 block
			&.required
				&::after
					content: "("'*'")"
					display: inline-block
					font-size: r(16px)
					@apply text-red
		input,textarea,select
			@apply bg-white text-clamp-14 w-full px-5 border-transparent !#{important}
			height: 34px !important
			&::placeholder
				@apply text-grey-300
		.datepicker-custom
			@apply border border-grey-100 rounded 
			height: 37px !important
		input[type='submit']
			@apply bg-primary-600  text-white font-bold 
	.RadPicker
		width: 100% !important
	.frm-btnwrapper
		@apply flex items-end justify-end
		@media screen and ( max-width: 1024px)
			@apply mt-4
		input[type='submit']
			background: #e31e26 !important
			color: white !important
			font-weight: 700 !important
			@apply overflow-hidden rounded
.wrap-register
	label
		@apply leading-none
	input[type='text'],input[type='password']
		height: 32px !important
		max-height: 32px !important
		@apply py-1
.order-total 
	width: 450px 
	max-width: 100% 
	margin-left: auto 
	margin-top: 1rem 
	font-size: 14px 
.login-logo,
.pagetitle,
.postname,
.wrap-recover h1 
	font-size: 1.5rem 
	text-align: center 
	line-height: 1.375 
	font-weight: 700 
	margin-bottom: 1.25rem 
.posttitle,
.wrap-resetpass h1 
	font-size: 1.25rem 
	margin-bottom: 1.25rem 
	line-height: 1.375 
	@apply text-clamp-18 font-bold uppercase
.loginstandard 
	.cart-login 
		.social-login 
			padding: 0 
.postname 
	text-align: left 
.login-box-msg 
	font-size: 1.125rem 
	margin-bottom: 1rem 
	@apply text-center text-clamp-15 font-semibold
.login-box input[type="text"]::placeholder,
.login-box input[type="password"]::placeholder
	@apply text-clamp-14 font-light

.login-box input[type="text"],
.login-box input[type="password"],
.login-box select,
.wrap-register input[type="text"],
.wrap-register input[type="password"],
.wrap-register select,
.account-form input[type="text"],
.account-form input[type="password"],
.account-form select,
.address-form input[type="text"],
.address-form input[type="password"],
.address-form select,
.wrap-recover input[type="text"],
.wrap-recover input[type="password"],
.wrap-recover select,
.wrap-resetpass input[type="text"],
.wrap-resetpass input[type="password"],
.wrap-resetpass select 
	border: 0 !important 
	border-bottom: 1px solid #d7d7d7 !important 
	padding: 12px !important 
	width: 100% !important 
	height: auto !important 
.login-box input[type="submit"],
.wrap-register input[type="submit"],
.account-form input[type="submit"],
.address-form input[type="submit"],
.wrap-recover input[type="submit"],
.wrap-resetpass input[type="submit"] 
	height: 2.5rem 
	min-width: 8.125rem 
	font-size: 1rem 
	color: #fff 
	border: 0 
	background: #e31e26 
	font-weight: 700 
	display: block 
	margin-left: auto 
	border-radius: 4px
	overflow: hidden
	padding: 4px 20px
.login-box input:disabled,
.wrap-register input:disabled,
.account-form input:disabled,
.address-form input:disabled,
.wrap-recover input:disabled,
.wrap-resetpass input:disabled 
	color: #666 
	background-color: transparent 
	font-style: italic 
.login-box 
	input[type="submit"] 
		width: 100% 
		border-radius: 20px 
.wrap-register .settingrow > *,
.account-form .settingrow > *,
.address-form .settingrow > *,
.wrap-recover .settingrow > *,
.wrap-resetpass .settingrow > * 
	-webkit-box-flex: 0 
	-ms-flex: 0 0 100% 
	flex: 0 0 100% 
	max-width: 100% 
.wrap-register .form-group,
.account-form .form-group,
.address-form .form-group,
.wrap-recover .form-group,
.wrap-resetpass .form-group 
	margin-bottom: 20px 
.wrap-register .form-group label,
.account-form .form-group label,
.address-form .form-group label,
.wrap-recover .form-group label,
.wrap-resetpass .form-group label 
	line-height: 1.5 
.wrap-register .form-group span,
.account-form .form-group span,
.address-form .form-group span,
.wrap-recover .form-group span,
.wrap-resetpass .form-group span 
	width: 100%
	font-family: 'Montserrat', sans-serif
	&::before
		font-family: 'Font Awesome 6 Pro'
.passwordrecovery input[type="submit"],
.wrap-register input[type="submit"] 
	margin: 0 auto 
.fa-exclamation-triangle 
	font-size: 14px
	color: red 
	line-height: 2 
	font-weight: 700 !important
.profile-container 
	padding: 3rem 15px 
.user-sidebar,
.user-sidelink 
	padding: 1.875rem 
	background: #fff 
	border-radius: 10px 
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
	.user-agency-info
		@apply font-bold mb-5 text-primary-600 text-clamp-18 block
.userinfo 
	figure 
		display: -webkit-box 
		display: -ms-flexbox 
		display: flex 
		-webkit-box-align: center 
		-ms-flex-align: center 
		align-items: center 
		img 
			border-radius: 50% 
		figcaption 
			padding-left: 20px 
			color: #223771 
			line-height: 1.375 
	.user-name 
		font-weight: 700 
		font-size: 1.125rem 
.user-sidelink 
	margin-top: 1.875rem 
	ul 
		li 
			& + li 
				margin-top: 15px 
				border-top: 1px solid #eee 
				padding-top: 15px 
				&.active 
					a 
						color: #e31e26 
.account-info,
.coin-info,
.cart-history,
.address-info,
.order-info,
.order-information,
.order-product,
.order-total 
	padding: 1.25rem 
	background: #fff 
	border-radius: 10px 
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
	line-height: 1.375 
.account-info .link,
.coin-info .link,
.cart-history .link,
.address-info .link,
.order-info .link,
.order-information .link,
.order-product .link,
.order-total .link 
	color: #5bc0de 
	font-size: 0.875rem 
.address-info .check-group label
	@apply text-clamp-14 ml-1

.account-order
	@apply overflow-x-auto
	table
		@apply w-full whitespace-nowrap
		thead
			th
				@apply bg-primary-600 text-white
				*
					@apply text-white
		tbody
			td
				@apply text-center
				&:nth-child(1),&:nth-child(2),&:nth-child(3)
					@apply text-left
					
		.name
			width: 200px
			white-space: normal
.info-heading 
	display: -webkit-box 
	display: -ms-flexbox 
	display: flex 
	-webkit-box-align: center 
	-ms-flex-align: center 
	align-items: center 
	-webkit-box-pack: justify 
	-ms-flex-pack: justify 
	justify-content: space-between 
	margin-bottom: 1.25rem 
	.link 
		color: #5bc0de 
		font-size: 0.875rem 
.info-heading .posttitle,
.info-heading .postname 
	margin-bottom: 0 
	@apply text-clamp-18 font-bold uppercase
.account-info 
	.info-detail 
		.group 
			.label 
				color: #787878 
.address-list 
	.address-col 
		& + .address-col 
			padding-top: 1.25rem 
			margin-top: 1.25rem 
			border-top: 1px dashed #eee 
	.address 
		font-size: 0.875rem 
		position: relative 
		.type 
			-webkit-box-align: center 
			-ms-flex-align: center 
			align-items: center 
			background-color: #e31e26 
			border-radius: 4px 
			color: #fff 
			display: -webkit-inline-box 
			display: -ms-inline-flexbox 
			display: inline-flex 
			font-size: 0.75rem 
			height: 2em 
			-webkit-box-pack: center 
			-ms-flex-pack: center 
			justify-content: center 
			line-height: 1.5 
			padding-left: 0.75em 
			padding-right: 0.75em 
			white-space: nowrap 
			margin-bottom: 0.9375rem 
			position: absolute 
			right: 0 
			top: 0 
		.btn-editaddr 
			color: #5bc0de 
			text-decoration: underline 
			text-underline-position: under 
			display: inline-block 
			margin-top: 0.625rem 
		.btn-deleteaddr 
			color: #999 
			margin-left: 0.625rem
.cart-history
	.title-wrap
		@apply mb-6
	.posttitle
		@apply mb-0 
.cart-history,
.order-product 
	overflow: auto 
.cart-history table,
.order-product table 
	width: 100% 
	font-size: 14px 
.cart-history table thead th,
.order-product table thead th 
	font-weight: 700 
	padding: 0.75rem 0.75rem 
	border-bottom: 1px solid #dbdbdb 
.cart-history table tbody td,
.order-product table tbody td 
	border: 1px solid #dbdbdb 
	border-width: 0 0 1px 
	padding: 0.75rem 0.75rem 
	vertical-align: top 
.cart-history table tbody td a,
.order-product table tbody td a 
	display: block 
	color: #5bc0de 
.cart-history table tbody td a + .name,
.order-product table tbody td a + .name 
	margin-top: 5px 
.cart-history table tbody td a:hover,
.order-product table tbody td a:hover 
	text-decoration: underline 
.cart-history table tbody tr:last-child td,
.order-product table tbody tr:last-child td 
	border-bottom: 0 
.order-product 
	table 
		td 
			text-align: center 
			.product 
				display: -webkit-box 
				display: -ms-flexbox 
				display: flex 
				-webkit-box-align: center 
				-ms-flex-align: center 
				align-items: center 
				.img 
					width: 80px 
				.caption 
					-webkit-box-flex: 1 
					-ms-flex: 1 1 0% 
					flex: 1 1 0% 
					padding-left: 20px 
					font-size: 14px 
.order-product table td:first-child,.order-product table th:first-child 
	width: 50% 
.order-product table td .product .caption,.order-product table th .product .caption 
	text-align: left 
.order-total 
	table 
		width: 100% 
		tr 
			td 
				text-align: right 
				padding: 0.5rem 0.75rem 
				&.total 
					font-weight: 700 
					font-size: 18px 
					color: #e31e26 
			&:first-child 
				td 
					padding-top: 0 
.regcomplete span,
.wrap-confirmreg span 
	display: block 
	margin-bottom: 20px 
	color: #155724 
	background-color: #d4edda 
	border-color: #c3e6cb 
	padding: 0.75rem 1.25rem 
.order-information 
	.title 
		font-weight: 700 
		font-size: 18px 
		margin-bottom: 15px 
		padding-bottom: 10px 
		border-bottom: 1px dashed #ccc 
	ul 
		font-size: 14px 
		li 
			&.name 
				font-size: 16px 
.profile-container
	padding: r(60px) 0
.checkout-login-page
	.cart-login
		max-width: 550px
		width: 100%
		margin: 0 auto
		.login-heading
			text-align: center
			color: #e31e26
			font-size: r(32px)
			margin-bottom: r(30px)
			font-weight: bold
			text-align: center
		.login-form
			width: 100%
			background-color: #fff
			padding: r(30px)
			margin: 0 auto
			border-radius: 25px
		.module-title
			color: #e31e26
			font-size: 14px
			font-style: italic
			font-weight: 600
			margin-top: 10px
			margin-bottom: 20px
	.form-group
		& + .form-group
			margin-top: 20px
		label
			display: none
		input
			width: 100%
			background: #FFFFFF
			border: thin solid #E1E1E1
			border-radius: 4px
			font-weight: 400
			font-size: r(14px)
			color: #e31e26
			padding: 10px 15px
			&::placeholder
				font-weight: 400
				font-size: r(14px)
				color: #e31e26
	.login-form
		.form-group
			label
				@apply hidden
	.select-group
		ul
			padding: 0
		input
			display: none
			&:checked
				~ label
					&::before
						font-weight: 900
						content: "\f14a"
						@apply text-main

		label
			display: flex
			align-items: center
			font-style: normal
			font-weight: 400
			font-size: r(16px)
			color: #111111
			width: fit-content
			cursor: pointer
			&::before
				transition: .3s all ease-in-out
				font-size: r(20px)
				font-family: 'Font Awesome 5 Pro'
				color: #e31e26
				font-weight: 300
				content: "\f0c8"
				margin-right: 10px
				@media (min-width: 576px)
					margin-right: 20px
.address-edit-page,.purchase-history-page,.favorite-product-page,.edit-address-page
	padding: 50px 0
	.row
		margin-bottom: 0 !important
		.col
			margin-bottom: 0 !important