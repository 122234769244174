main
	padding-top: 100px
	@media screen and ( max-width: 1200px)
		padding-top: 60px
.swiper-slide
	height: auto 
.five-swiper,.four-swiper,.three-swiper,.two-swiper,.single-swiper
	@apply relative z-50
	.swiper
		@apply -m-3 p-3 -mx-8 px-2
		@media screen and ( max-width: 1200px)
			@apply -mx-6
		@media screen and ( max-width: 1024px)
			@apply -mx-5
	.swiper-slide
		padding: 0 16px
		@media screen and ( max-width: 1200px)
			padding: 0 10px
	
.five-swiper
	.swiper-slide
		@apply w-1/5
		@media screen and ( max-width: 1200px)
			@apply w-1/4
		@media screen and ( max-width: 1024px)
			@apply w-1/3
		@media screen and ( max-width: 768px)
			@apply w-1/2
		@media screen and ( max-width: 460px)
			@apply w-full
.four-swiper
	.swiper-slide
		@apply w-1/4
		@media screen and ( max-width: 1024px)
			@apply w-1/3
		@media screen and ( max-width: 768px)
			@apply w-1/2
		@media screen and ( max-width: 460px)
			@apply w-full
.three-swiper
	.swiper-slide
		width: 33.33%
.two-swiper
	.swiper-slide
		width: 50%
.single-swiper
	.swiper-slide
		width: 100%
.main-menu-toggle
	@apply hidden
	@media screen and ( max-width:  1024px)
		@apply flex items-center pl-8
	@media screen and ( max-width:  768px)
		@apply pl-4
.frm-thanks
	@apply my-16 p-8 text-center
	margin: 0 auto
	width: 500px
	h3,h4,h5
		@apply text-red font-bold text-red text-16 mb-3
	p,span
		@apply text-14 text-grey-333 font-normal
	@media screen and ( max-width: 1024px)
		width: 320px
.tab-item
	@apply hidden
	&.active
		@apply block
.edit-link
	width: 30px
	height: 30px
	pointer-events: auto
	.fa-pencil
		width: 18px
		height: 18px
		background-size: contain
		background-repeat: no-repeat
		background-position: center
		background-image: url("data:image/svg+xml;charset=utf-8,<svg  viewBox='0 0 18 18'  xmlns='http://www.w3.org/2000/svg'><path d='M6.35473 15.7485L2.27202 11.6658L12.1468 1.79106L16.2295 5.87378L6.35473 15.7485ZM1.89039 12.385L5.63556 16.1302L0.0205078 18L1.89039 12.385ZM17.4852 4.62344L16.7771 5.33159L12.6889 1.24347L13.3971 0.535326C14.1104 -0.178442 15.2672 -0.178442 15.9805 0.535326L17.4852 2.04001C18.1934 2.75572 18.1934 3.90795 17.4852 4.62344Z' fill='#094594'/></svg>")
.custom-select
	@apply relative w-full
	&::after
		content: '\f078'
		font-family: 'Font Awesome 6 Pro'
		@apply block absolute-y pointer-events-none right-[20px] z-10  transition leading-none font-normal text-grey-700 text-clamp-14
		height: 16px
		width: 8px
		font-size: 14px
	select
		-webkit-appearance: none
		-moz-appearance: none
		height: 44px
		@apply rounded bg-white w-full text-clamp-14
		min-width: 144px
		padding: 8px 60px 8px 20px
		@media screen and ( max-width: 1024px)
			height: 40px
.scollbar-wrap
	height: 300px
	padding-right: 10px
	overflow-y: auto
	scrollbar-width: thin
	scrollbar-color: #e31e26 #cccccc 
	&::-webkit-scrollbar
		background: #cccccc
		width: 5px
		@apply rounded-5
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px #cccccc
		background-color: #cccccc
		@apply rounded-5
	&::-webkit-scrollbar-thumb
		background-color: #e31e26
		@apply rounded-5
	@media screen and ( max-width:1024px)
		height: auto
.qradio
	input
		background-repeat: no-repeat
		background-position: center
		appearance: none
		@apply block
		width: 20px
		height: 20px
		background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
		&:checked
			background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
.table-wrap
	@media screen and ( max-width:1024px)
		overflow-x: auto
	table
		@apply w-full
		@media screen and ( max-width:1024px)
			white-space: nowrap
.sidenav-group
	@apply fixed  right-[20px] z-50 bottom-[60px]  transition 
	@media screen and ( max-width: 576px)
		@apply right-[5px]
	li
		@apply mb-2
		@screen xl
			@apply mb-3
	em
		@apply text-white text-clamp-20 transition
		@screen xl
			@apply text-clamp-24
	&.show
		.back-to-top
			@apply opacity-100 pointer-events-auto
	a
		@apply center-item rounded-full cursor-pointer transition
		width: r(55px)
		height: r(55px)
		&:hover
			@apply no-underline
	
	.other
		@apply bg-white border-white shadow-lg
		img
			@apply w-full h-auto

	.contact
		@apply border border-primary-700 bg-primary-600
		&:hover
			@apply bg-white
			em
				@apply text-primary-600
.back-to-top
	@apply  bg-grey-500  border border-white border-opacity-50 opacity-0 pointer-events-none mb-3
	&:hover
		@apply bg-primary-600
.drop-list
	em,span
		@apply  leading-none
	.fa-globe
		@apply hidden
		@screen sm
			@apply inline-block
	em
		@apply text-clamp-16
		@screen sm
			@apply text-clamp-18
		@screen xl
			@apply text-clamp-16 
	span
		@apply text-clamp-16 font-normal mr-3
		@screen sm
			@apply mx-3
	&:hover
		.fa-chevron-down
			transform: rotateX(180deg)
		.dropdown
			@apply filter-none pointer-events-auto opacity-100
	.drop-title
		@apply px-5 py-2 overflow-hidden rounded-5 leading-none center-item h-full border-white  border border-opacity-50
		span
			@media screen  and ( max-width: 768px)
				@apply hidden
		.fa-chevron-down 
			@media screen  and ( max-width: 768px)
				@apply ml-3
	.dropdown
		filter: blur(1px)
		@apply opacity-0 pointer-events-none transition bg-white px-8 py-3  col-ver absolute-x top-full  w-max rounded overflow-hidden z-40 shadow-md
		@media screen and ( max-width: 1400px)
			@apply px-5
		li
			&:not(:last-child)
				@apply mb-2
			&.active
				@apply font-bold
		a
			@apply text-grey-333 font-semibold start-item text-left text-clamp-15
			@screen xl
				@apply text-clamp-14
			&:hover
				@apply underline
.zone-nav
	li
		&:not(:last-child)
			@apply mr-5
		&.active,&:hover
			a
				@apply bg-primary-600 text-white
	a
		@apply transition
		height: r(52px)
		min-height: 40px