.btn
	span,em
		transition: .4s all  ease-in-out
		@apply leading-none
	&.btn-primary
		@apply border border-primary-600 relative bg-primary-600 rounded
		padding: 8px r(24px)
		height: r(44px)
		min-height: 40px
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-0 h-full  bg-white
		&:hover
			&::after
				@apply w-full
			span,em 
				@apply text-primary-600
				
		span,em
			@apply  font-semibold text-clamp-14 relative z-40 text-white
		em
			@apply ml-3 text-clamp-20
			@screen xl
				@apply ml-4
	&.btn-secondary
		@apply border border-primary-600 relative bg-white rounded
		padding: 8px r(24px)
		height: r(44px)
		min-height: 40px
		&:hover
			@apply bg-primary-600
			span,em 
				@apply text-white
				
		span,em
			@apply  font-semibold text-clamp-14 relative z-40 text-primary-600
		em
			@apply ml-3 text-clamp-20
			@screen xl
				@apply ml-4
	&.btn-tertiary
		@apply pr-1  justify-start
		span
			@apply text-clamp-14 font-semibold text-grey-950
		em
			@apply ml-2 text-clamp-20 text-primary-600 leading-tight
		&:hover
			em
				transform: translateX(3px)
.swiper-nav 
	@media screen and ( max-width: 1023.98px) 
		@apply center-item pt-8
	.prev,.next
		@apply  transition overflow-hidden rounded-full cursor-pointer center-item  transition  border border-grey-ea bg-white
		width: 40px
		height: 40px
		line-height: 40px
		@screen lg
			@apply absolute-y z-10
		@media screen and ( max-width: 1023.98px)
			@apply relative z-50 mx-4
		&:hover
			@apply border-transparent bg-primary-600 border-primary-600
			em
				@apply text-white
		em
			@apply inline-block transition leading-none  relative z-40 text-grey-950
			font-size: 16px
			height: 16px
			line-height: 16px
			@media screen and ( max-width: 1024px)
				font-size: 20px
				height: 20px
				line-height: 20px
	
	&.normal
		padding: 0 !important
		margin: 0 !important
		.next,.prev
			left: unset !important
			right: unset !important
			position: relative !important
			transform: none !important
		.prev
			margin-right: 16px
	&.inset
		.next
			right: 0
		.prev
			left: 0
	&.white
		.next,.prev
			@apply border-white
			em
				@apply text-white

	.next
		@media screen and ( min-width: 1440.1px)
			right: -80px
		@media screen and ( min-width: 1024px) and ( max-width: 1440px)
			right: -60px
	.prev
		@media screen and ( min-width: 1440.1px)
			left: -80px
		@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
			left: -60px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply  flex-wrap
	justify-content: flex-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
.txt-hor
	@apply h-full col-hor
