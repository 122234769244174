.checkout-complete
	.figure
		max-width: 700px
		@apply mx-auto shadow-lg overflow-hidden rounded
	.img
		a
			+img-ratio(300,700)
	.txt
		@apply p-7
		@screen lg
			@apply p-8
	.item
		@apply text-clamp-16
		*
			@apply text-clamp-16

		p
			&:last-child
				@apply mb-0
			&:first-child
				@apply mb-2
				@apply text-grey-300 text-clamp-14
				*
					@apply text-grey-300 text-clamp-14
	address
		@apply text-clamp-16 font-medium not-italic
		*
			@apply text-clamp-16 font-medium not-italic
		li
			word-break: break-word
			*
				word-break: break-word
			&:not(:last-child)
				@apply mb-1
		span,a
			@apply text-gradient-1
			*
				@apply text-gradient-1
	.btn
		&.btn-primary
			&:hover
				em
					transform: none !important
	.contact-info
		@media screen and ( max-width: 576px)
			@apply col-ver 
			.right
				@apply pt-4
	.txt-content
		@apply text-clamp-16 font-medium text-center
		word-break: break-word
		*
			@apply text-clamp-16 font-medium
			word-break: break-word
		p
			@apply mb-1
		strong
			@apply font-bold
			*
				@apply font-bold
		span,a
			@apply  text-primary-600 font-semibold inline-block ml-[2px]
			*
				@apply  text-primary-600 font-semibold
		ul,ol
			list-style-type: disc
			padding-left: 0
			li
				@apply mb-3 flex items-start
				&::before
					content: ''
					@apply block mt-2 pointer-events-none z-10  transition w-[8px] h-[8px] bg-white rounded-full mr-3