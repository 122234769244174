.checkout-info,.cart-page
	.wrap-right
		.btn-secondary,.btn-primary
			min-width: 250px
			span
				@apply uppercase
		.btn-primary
			font-weight: 600 !important
			text-transform: uppercase !important
			@apply text-clamp-14
		.checkout-btn-wrap
			@apply relative z-20
			@media screen and ( max-width: 576px)
				@apply w-full
			&:hover
				&::after
					@apply text-primary-600
				input[type='submit']
					@apply bg-white text-primary-600
			&::after
				font-family: 'Font Awesome 6 Pro'
				content: "\f178"
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[35px]  z-10  transition font-black text-white  text-clamp-20
				height: 18px
				width: 20px
				line-height: 18px
				@media screen and ( max-width: 1024px)
					right: 30px
				@media screen and ( max-width: 576px)
					right: calc(50% - 80px)
		@media screen and ( max-width: 576px)
			@apply w-full flex col-ver
			.btn-secondary
				@apply w-full mb-4
				min-width: 250px
			.btn-primary
				min-width: 250px
				@apply w-full

.checkout-ticket
.checkout-tour
	.checkout-summary
		tr
			&:not(:last-child)
				td
					@apply  border-b border-b-grey-800
.checkout-shopping
	.checkout-summary
		.txt
			@apply gap-wrap w-full
			align-items: flex-start
		.right
			@apply text-right pl-2
			p
				&:not(:last-child)
					@apply mb-1
		.img
			a
				@apply bg-grey-333 p-2
				width: 80px
				height: 80px
				min-width: 80px
		.code
			@apply text-grey-300
			*
				@apply text-grey-300
			span
				@apply text-white
				*
					@apply text-white

.checkout-wrap
	@apply bg-white shadow-lg
	.error
		font-family: 'Quicksand', sans-serif
		@apply absolute font-bold text-red
		font-size: 12px
		bottom: -18px
		left: 200px
		@media screen and ( max-width: 768px)
			font-size: 10px
			left: 0
			@apply font-bold
	.fa-exclamation-triangle
		font-family: 'Quicksand', sans-serif
		@apply  font-bold text-red mt-1
		font-size: 12px
		@media screen and ( max-width: 768px)
			font-size: 10px
			left: 0
			@apply font-bold
		&::before
			@apply font-Awesome6
	.title-line
		@apply py-4
	.card-body
		@apply p-6
		@screen lg
			@apply px-8 
	.custom-select
		&::before
			@apply text-white
	.form-group
		@apply relative
		&:not(:last-child)
			@apply mb-[20px]
		label
			@apply text-grey-950 font-medium text-clamp-14 mb-2
			&.required
				&::after
					content: "("'*'")"
					display: inline-block
					font-size: r(16px)
					@apply text-red
		input[type='text'],input[type='tel'],input[type='email'],input[type='password'],textarea,select
			@apply bg-white text-clamp-14 w-full px-5 border border-grey-100
			height: 42px
			&::placeholder
				@apply text-grey-300

		.custom-select
			select
				padding-right: 50px
		textarea
			height: 80px
			@apply pt-3
.checkout-address
	.note
		@apply text-grey-999 font-normal text-clamp-14 text-left italic w-full
		*
			@apply text-grey-999 font-normal text-clamp-14 italic
// .checkout-address
// 	.custom-select
// 		@apply w-full
// 		&::after
// 			top: 38px !important
// 			transform: none !important
// 	.form-group
// 		@apply gap-wrap 
// 		label
// 			width: 200px
// 			min-width: 200px
// 		@media screen and ( max-width: 768px)
// 			@apply block
// 			label
// 				@apply block mb-3
// 	.note
// 		@apply text-grey-999 font-normal text-clamp-14 text-left italic w-full
// 		*
// 			@apply text-grey-999 font-normal text-clamp-14 italic
.checkout-payment
	.checkout-form
		>div
			@apply  w-full
	.fa-exclamation-triangle
		@apply ml-6
.checkout-payment,.checkout-shipping
	.checkout-form
		@apply pl-0
	.qradio
		position: relative
		margin-bottom: 30px
		.icon
			@apply pt-4 h-full center-item border-r border-r-grey-100 
			width: 75px
			@screen sm
				@apply  bg-white pt-0
		.txt
			@apply px-4 py-2 text-center
			@screen sm
				@apply text-left 
			@screen lg
				@apply p-5 py-3
		.title
			@apply text-grey-950 font-bold text-clamp-16 mb-2
			*
				@apply text-grey-950 font-bold text-clamp-16
			@screen sm
				@apply mb-1
		.dsc
			@apply text-clamp-14 font-normal text-grey-300
			*
				@apply text-clamp-14 font-normal text-grey-300
			p
				@apply mb-1
			span
				@apply text-grey-950
				*
					@apply text-grey-950
		label
			@apply bg-white flex p-0 border border-grey-100
			@media screen and ( min-width:  576.1px)
				justify-content: flex-start
				align-items: center
				height: 75px
				min-height: 75px
			@media screen and ( max-width:  576px)
				flex-direction: column
				justify-content: center
				align-items: center
	.table-checkout-payment
		@apply w-full
		>tbody
			>tr
				>td
					padding-left: 25px
		td
			@apply relative text-clamp-14 
			*
				@apply text-clamp-14
		.icon
			@apply pt-4 h-full center-item 
			width: 75px
			@media screen and ( max-width: 576px)
				@apply border-b border-b-grey-100
			@screen sm
				@apply  bg-white pt-0 border-r border-r-grey-100 
		.text-wrap
			@apply p-4  text-center text-clamp-14 
			*
				@apply text-clamp-14 
			@screen sm
				@apply text-left 
			@screen lg
				@apply p-5 py-3
			table
				@apply w-full
				tr
					@media screen and ( max-width: 576px)
						@apply flex flex-col w-full
						&:not(:last-child)
							@apply  mb-2
				td
					@apply text-clamp-14  w-full
					&:first-child
						@apply w-full
						@media screen and ( min-width: 576px)
							min-width: r(180px)
							max-width: r(180px)
							width: r(180px)
						@media screen and ( min-width: 1330px)
							min-width: r(200px)
							max-width: r(200px)
							width: r(200px)
					*
						@apply text-clamp-14
		.img
			@apply p-2 h-full center-item
			@media screen and ( max-width: 576px)
				@apply border-b border-b-grey-400 pt-4
			@screen sm
				@apply  bg-white pt-0 border-r border-r-grey-400
		.title
			@apply text-grey-950 font-bold text-clamp-16 mb-2
			*
				@apply text-grey-950 font-bold text-clamp-16
			@screen sm
				@apply mb-1
		.dsc
			@apply text-clamp-14 font-normal text-grey-300
			*
				@apply text-clamp-14 font-normal text-grey-300
			p
				@apply mb-1
			span
				@apply text-grey-950
				*
					@apply text-grey-950
		label
			@apply bg-white flex p-0 border border-grey-100
			@media screen and ( min-width:  576.1px)
				justify-content: flex-start
				align-items: center
				min-height: 75px
			@media screen and ( max-width:  576px)
				flex-direction: column
				justify-content: center
				align-items: center
		input[type='radio']
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			width: 100%
			height: 100%
			opacity: 0
			z-index: 20
			&:checked ~ label
				&::before
					background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
		label
			@apply  relative z-10 pointer-events-none transition 
			&::before
				content: ''
				background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
				background-repeat: no-repeat !important
				background-size: contain !important
				background-position: center !important
				display: block
				position: absolute
				pointer-events: none
				left: -30px
				top: 50%
				transform: translateY(-50%)
				z-index: 9
				height: 18px
				width: 18px
				transition: .3s all ease-in-out
.checkout-invoice
	.qradio
		@apply start-item pl-[30px]
	input[type='radio']
		@apply mr-3
	.checkout-form
		@apply py-4
		@screen lg
			@apply pt-5 pb-7
.checkout-summary
	.cart-item
		@apply flex justify-start items-start mb-4
		@screen lg
			@apply mb-5
	.img
		a
			@apply center-item
			height: 70px
			width: 100%
			min-width: 120px
			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		@apply pl-3
	.table-wrap
		@apply  border-b border-b-grey-800 py-1
	.discount
		@apply text-red
		*
			@apply text-red
	table
		@apply w-full  
	td
		@apply text-grey-950 text-left py-3
		*
			@apply text-grey-950
		&:first-child
			@apply text-clamp-16 font-bold
			*
				@apply text-clamp-16 font-bold
			span
				@apply text-clamp-14 font-normal 
				*
					@apply text-clamp-14 font-normal
		&:last-child
			@apply text-right text-clamp-14
			*
				@apply text-clamp-14
			p
				&:last-child
					@apply mb-0
	.price-wrap
		@apply border-t border-t-grey-800 pt-4 mt-4