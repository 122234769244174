.product-detail-page
	.product-detail 
		@apply overflow-hidden
	.top-banner
		@apply hidden
.product-detail
	@apply bg-white
	.row
		@apply m-0 p-0
		.col
			@apply m-0 p-0
	.options
		@apply absolute top-[20px] right-[20px] z-20 pointer-events-none text-clamp-14 text-white font-semibold start-item
		align-items: center
		*
			@apply text-clamp-14 text-white font-semibold leading-none
		em
			@apply text-clamp-12 ml-2
			*
				@apply text-clamp-12
		.discount,.types
			@apply overflow-hidden rounded center-item 
			padding: 2px r(10px)
			height: 22px
			min-height: 22px
		.discount
			background: #FFB617
			@apply mr-[10px]
			&:not(:last-child)
				@apply mr-2
		.types
			background: #E41913
			&:not(:last-child)
				@apply mr-2
	.txt-wrap
		@media screen and ( max-width: 1024px)
			@apply pt-8
		@media screen and ( max-width: 576px)
			@apply pt-5
		@screen lg
			@apply pl-8 
	.swiper-nav
		@screen sm
			@apply absolute bottom-[20px] right-[20px] z-20
	.nav-prev,.nav-next
		&:hover
			@apply bg-primary-600
			em
				@apply text-white 
	.product-swiper-wrap
		@media screen and ( max-width: 576px)
			padding-bottom: 60px
			.swiper-nav
				@apply absolute-x bottom-0
	.swiper-product-main
		@media screen and ( max-width: 576px)
			@apply static
		.img
			a
				@apply overflow-hidden rounded
				+img-ratio(523,784)
	.swiper-product-thumb
		@apply mt-5
		.img
			a
				@apply overflow-hidden rounded border border-transparent transition
				display: flex
				height: r(100px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
		.swiper-slide-thumb-active
			a
				@apply border-primary-600
	.rating
		&::after
			content: ''
			@apply block absolute-y pointer-events-none left-full  z-10  transition w-[60px] h-[1px] bg-primary-600
		.star
			@apply mx-[10px]
			em,i
				color: #F29E4C 
				@apply text-clamp-16
				&:not(:last-child)
					@apply mr-1
	.percent
		padding: 2px r(10px)
		height: 22px
		min-height: 22px
		background: #FFB617
	.old
		color: #818285
		@apply line-through
	.tour-info-item
		@apply start-item mb-3
		i
			@apply text-primary-600 text-clamp-14 inline-block 
		.label  
			@apply text-clamp-14 font-medium inline-block mx-3 text-grey-950 whitespace-nowrap mb-0
		.custom-select
			@apply w-fit
			select
				@apply shadow-xl  overflow-hidden rounded
				padding-right: 50px !important
				min-width: 150px
				width: 150px
				height: 40px
		.cursor
			@apply ml-3
	.table-wrapper
		@apply pt-1
		table
			@apply w-full

		td
			@apply text-clamp-16 font-medium text-left py-3 border-b border-b-grey-200
			*
				@apply text-clamp-16 font-medium
			&:first-child
				@apply font-bold
				*
					@apply font-bold
	.btn-wrap
		@apply justify-between gap-4
		flex-wrap: nowrap
		.btn
			@apply w-1/2 m-0
			em
				@apply mr-3 ml-0 text-clamp-14
			span
				@apply uppercase
	.btn-bottom
		em
			@apply mr-2 text-clamp-14
.product-detail-2
	.title-line
		@apply mb-4
	.block-wrap
		@apply pb-4
	.stick-content
		@screen lg
			@apply sticky top-[100px]
	.table-content
		@media screen and ( max-width: 1024px)
			@apply fixed top-[60px] w-full h-fit left-0 z-50 p-6 bg-grey-50
		
		ul
			@media screen and ( max-width: 1024px)
				@apply hidden
		li
			&:first-child
				a
					@apply pt-0
			&.active,&:hover
				a
					@apply text-primary-600 border-b-primary-600
		a
			@apply py-3 start-item font-medium text-clamp-16 border-b border-grey-200 transition
	.contact-info
		@apply not-italic
		*
			@apply not-italic
		address
			@apply p-5
			li
				@apply py-3 col-hor border-b border-grey-200 transition  font-medium text-clamp-16 
				*
					@apply font-medium text-clamp-16 
				p
					@apply mb-0
				&:first-child
					@apply pt-0
				em,strong
					@apply text-primary-600 text-clamp-14 leading-none
				em
					@apply mr-3
				strong
					@apply uppercase font-bold
					*
						@apply uppercase font-bold
		.social
			@apply start-item pt-5
			a
				@apply center-item
				&:not(:last-child)
					@apply mr-4
.tour-recently-view
	@apply hidden
.product-recently
	.tour-recently-view
		display: block !important