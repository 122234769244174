.news-list
	.zone-nav
		@media screen and ( max-width: 768px)
			+res-nav
	.md\:w-1\/3
		.news-item
			.headline
				a
					@apply text-clamp-16
.news-item
	@apply border-b border-b-grey-200 h-full flex flex-col transition
	.img
		flex: 0 1 auto
		a
			+img-ratio(428,640)
	.txt
		flex: 1 0 auto
	time
		span
			@apply text-clamp-14 text-grey-500
	&:hover
		@apply border-b-primary-600
		.headline
			a
				@apply text-primary-600